import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  FaFacebook,
  FaLinkedin,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import "./ContactUs.css";

const ContactUs = ({ product, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    Number: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs
      .send(
        "service_qxcd66q",
        "template_6hfuwnf",
        {
          to_email: "ramashankar8290@gmail.com",
          from_name: formData.fullName,
          from_email: formData.email,
          message: formData.message,
          contact_number: formData.Number,
          product_name: product ? product.title : "General Inquiry",
        },
        "ugiBow7XqV4jFejlH"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Message sent successfully!");
          setFormData({ fullName: "", Number: "", email: "", message: "" });
          onClose();
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Failed to send message. Please try again.");
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="contact-form" onClick={(e) => e.stopPropagation()}>
        <h2 className="contact-title">
          Contact Us {product ? `about ${product.title}` : ""}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="fullName">Full Name:</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="Number">Contact Number:</label>
            <input
              type="tel"
              id="Number"
              name="Number"
              value={formData.Number}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">What can we help you with?</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Sending..." : "Submit"}
          </button>
        </form>
        <div className="social-icons">
          <a
            href="[Your Facebook URL]"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FaFacebook />
          </a>
          <a
            href="https://in.linkedin.com/company/xeroscape"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FaLinkedin />
          </a>
          <a
            href="[Your Twitter URL]"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <FaTwitter />
          </a>
          <a
            href="[Your Instagram URL]"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram />
          </a>
          <a
            href="https://wa.me/8072423972"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="WhatsApp"
          >
            <FaWhatsapp />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

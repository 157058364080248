import React from "react";
import "./Services2.css"; // Import the CSS file for styling

import bentoGrid from "../../Asset/Image/bentoGrid.png";

function Services2() {
  const features = [
    {
      title: "Innovation",
      description: "Pioneering trends",
    },
    {
      title: "Customization",
      description: "Tailored solutions",
    },
    {
      title: "Quality",
      description: "Uncompromised excellence",
    },
    {
      title: "Collaboration",
      description: "Team synergy",
    },
  ];

  return (
    <div className="different-from-others">
      <p className="Different">What makes us Different from Others?</p>

      <img className="responsive-image" src={bentoGrid} alt="" srcset="" />

      {/* <div className="feature-cards">
        {features.map((feature, index) => (
          <div className="feature-card" key={index}>
            <p className="title_different">{feature.title}</p>
            <p className="description_different">{feature.description}</p>
          </div>
        ))}
      </div> */}
    </div>
  );
}

export default Services2;

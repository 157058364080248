import React from "react";
import "./TrustedByBrands.css";
import nutech from "../Asset//Image/nutech.png";
import urbanrise from "../Asset//Image/urbanrise.jpg";
import alliance from "../Asset//Image/alliance-group-logo.png";
import gsquire from "../Asset//Image/gsquare.png";
// import iit from "../../Asset/Image/iit logo.png";
// import digirail from "../Asset/Image/Digirail_final-logo.png";
import nspire from "../Asset/Image/nspire.png";
import steps from "../Asset/Image/steps.jpg";
import llm from "../Asset/Image/lml.jpg";
import ggp from "../Asset/Image/GGP-Logo.png";
import trilight from "../Asset/Image/thetrilight_cover.jpg";
// import nst from "../Asset/Image/nst-logo.png";
import nst from "../Asset/Image/nst-logo.png";

const TrustedByBrands = () => {
  const logos = [
    { id: 1, src: nutech, alt: "Company 1" },
    { id: 2, src: urbanrise, alt: "Company 2" },
    { id: 3, src: alliance, alt: "Company 3" },
    { id: 4, src: gsquire, alt: "Company 4" },
    { id: 5, src: llm, alt: "Company 4" },
    { id: 6, src: ggp, alt: "Company 4" },
    { id: 7, src: trilight, alt: "Company 4" },
    { id: 8, src: steps, alt: "Company 4" },
    // {
    //   id: 5,
    //   src: iit,
    //   alt: "Company 5",
    //   companyName: "IITM Mechanical Department",
    // },
    // { id: 6, src: digirail, alt: "Company 6", companyName: "Rail lab" },
    {
      id: 9,
      src: nspire,
      alt: "Company 7",
      companyName: "Nspire Developers Llp",
    },
    { id: 10, src: nst, alt: "Company 8", companyName: "Nst Properties" },
  ];

  // Duplicate the logos for continuous effect
  const duplicatedLogos = [...logos, ...logos];

  return (
    <div className="trusted-by-brands">
      <div className="trusted-by-brands-content">
        <h2 className="trusted_main">Trusted by Leading Brands</h2>
        <p className="trusted_text">
          "Join the growing list of brands trusting Xeroverse for AR, VR, MR,
          3D, and Digital Twin solutions. Our commitment to excellence sets us
          apart."
        </p>
        <div className="logo-slider">
          <div className="logo-slider-wrapper">
            {duplicatedLogos.map((logo) => (
              <div key={logo.id} className="logo-item">
                <img src={logo.src} alt={logo.alt} />
                {logo.companyName && (
                  <p className="company_name">{logo.companyName}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedByBrands;

import Arvideo from "../../Asset/video/Arvideo.mp4";
import VR from "../../Asset/video/Vrreality.mp4";
import mrvideo from "../../Asset/video/Mrreality.mp4";
import "./ToggleContent.css";
import React, { useState, useEffect } from "react";

const ToggleContent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([
    {
      type: "AR",
      video: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/Arvideo.mp4",
      content: "Augmented Reality [AR]",
      content1:
        "Real-Time Interaction: AR enables seamless, interactive experiences with digital content in real-world surroundings.",
      content2:
        "Enhanced Perception: AR enriches our reality with 3D graphics, text, and animations, improving how we see and interact with the world.",
      content3:
        "Practical Applications: AR is used in gaming, entertainment, education, navigation, and product visualization, offering innovative solutions for everyday experiences.",
    },
    {
      type: "VR",
      video:
        "https://xeroverse-media.s3.ap-south-1.amazonaws.com/Vrreality.mp4",
      content: "Virtual Reality [VR]",
      content1:
        "Immersive Experience: VR creates a fully engaging, computer-generated environment, making users feel physically present in a different reality.",
      content2:
        " Headset Interaction: Users wear a VR headset that tracks head movements, providing a 360-degree view and enhancing presence and realism.",
      content3:
        "Applications: VR is used in gaming, education, simulations, and virtual tours, offering interactive and lifelike experiences.",
    },
    {
      type: "MR",
      video:
        "https://xeroverse-media.s3.ap-south-1.amazonaws.com/Mrreality.mp4",
      content: "Mixed Reality [MR]",
      content1:
        "Blending Worlds: Mixed reality seamlessly combines physical and digital realms, allowing virtual objects to interact with the real environment.",
      content2:
        " Interactive Experiences: Experience real-time interactivity with holograms and immersive digital content.",
      content3:
        "Spatial awareness: Seamlessly blend digital content with your world as mixed reality devices track and adapt to your movements.",
    },
  ]);
  const contentTypes = ["AR", "VR", "MR"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [data]);

  const toggleContent = (index) => {
    setCurrentIndex(index);
  };
  return (
    <>
      <div className="texthead_main">
        <div className="text_head">
          <p className="p1">Extended Reality</p>
          <p className="p2">
            We develop revolutionary technologies for extended reality and offer
            solutions for your business.
          </p>
        </div>

        <div className="vid_btn">
          {data.map((item, index) => (
            <div
              className="Video_text"
              key={index}
              style={{ display: index === currentIndex ? "flex" : "none" }}
            >
              <div className="toggle_video">
                <video autoPlay muted loop width="456px" height="248px">
                  <source src={item.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="content_toggle">
                <p className="toggle_text">{item.content}</p>
                <p className="toggle_text1">
                  <strong>{item.content1.split(":")[0]} :</strong>
                  {item.content1.split(":")[1]}
                </p>
                <p className="toggle_text1">
                  <strong>{item.content2.split(":")[0]} :</strong>
                  {item.content2.split(":")[1]}
                </p>
                <p className="toggle_text1">
                  <strong>{item.content3.split(":")[0]} :</strong>
                  {item.content3.split(":")[1]}
                </p>
              </div>
            </div>
          ))}

          <div className="btn_sec">
            {data.map((item, index) => (
              <button
                key={index}
                onClick={() => toggleContent(index)}
                className={currentIndex === index ? "button" : "button1"}
              >
                {item.type}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default ToggleContent;

import MidSec from "../Mid_Sec/category";
import Midbox from "../Mid_box/Mid_box";
import ToggleContent from "../ToggleContent/ToggleContent";
import FAQItem from "../FAQItem/FAQItem";
import TestimonialSlider from "../TestimonialSlider/TestimonialSlider";
import Services2 from "../Services2/Services2";
import Services3 from "../Services3/Services3";
import "./home.css";
import Hero from "../Header/Header";
import Services from "../Mid_box/Mid_box";
import TrustedByBrands from "../../TrustedByBrands/TrustedByBrands";
import Category from "../Mid_Sec/category";

function Home() {
  return (
    <div className="body_section">
      <Hero />
      <Category />
      <ToggleContent />
      <TrustedByBrands />
      <Services />
      <TestimonialSlider />
      <Services2 />
      {/* <Services3 /> */}
      <FAQItem />
    </div>
  );
}
export default Home;

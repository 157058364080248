import React, { useState, useEffect } from "react";
import "./TestimonialSlider.css";

const TestimonialSlider = () => {
  const testimonials = [
    {
      text: "Xeroverse's XR and Digital Twin solutions have revolutionized our project management. Their immersive VR and AR experiences have enhanced our design visualization.",
      company: "Lancor Holdings Ltd",
      position: "Project Director",
      rating: 5,
    },
    {
      text: "We integrated Xeroverse's cutting-edge AR technology into our real estate marketing, and the results were phenomenal. Their expertise is unmatched!",
      company: "Guru Punvaanii Properties Pvt Ltd",
      position: "Marketing Head",
      rating: 5,
    },
    {
      text: "Xeroverse's 3D modeling and data-driven solutions have significantly optimized our construction planning. Their innovation is a game-changer.",
      company: "LML Homes",
      position: "CTO",
      rating: 4,
    },
    {
      text: "Partnering with Xeroverse has elevated our property development process. Their XR solutions provide incredible insights and efficiency gains.",
      company: "Stepsstone Promoters Pvt Ltd",
      position: "Managing Director",
      rating: 5,
    },
    {
      text: "The VR and MR experiences developed by Xeroverse have transformed our customer engagement strategies. Highly professional and dedicated team!",
      company: "The Trilight",
      position: "CEO",
      rating: 5,
    },
    {
      text: "Xeroverse's Digital Twin solutions have helped us optimize our infrastructure projects with precise simulations and data analytics.",
      company: "G-Square Group",
      position: "Operations Manager",
      rating: 5,
    },
    {
      text: "With Xeroverse’s expertise in XR, we have streamlined our architectural designs and enhanced our client presentations like never before.",
      company: "Urbanrise",
      position: "Lead Architect",
      rating: 5,
    },
    {
      text: "Xeroverse is redefining real estate visualization with their stunning VR and AR solutions. Their technology has given us a competitive advantage.",
      company: "Alliance Group",
      position: "Chief Strategy Officer",
      rating: 5,
    },
    {
      text: "We trusted Xeroverse for our digital transformation, and they delivered beyond expectations. Their XR and 3D services have elevated our business.",
      company: "New Tech",
      position: "COO",
      rating: 4,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationDirection, setAnimationDirection] = useState("slide-in");
  const [lineAnimating, setLineAnimating] = useState(true);

  const goToSlide = (index) => {
    setAnimationDirection("slide-out");
    setLineAnimating(false);
    setTimeout(() => {
      setCurrentIndex(index);
      setAnimationDirection("slide-in");
      setLineAnimating(true);
    }, 500);
  };

  useEffect(() => {
    if (testimonials.length > 0) {
      const interval = setInterval(() => {
        goToSlide((currentIndex + 1) % testimonials.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [currentIndex, testimonials.length]);

  const StarRating = ({ rating }) => {
    return (
      <div className="star-rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <i key={star} className={star <= rating ? "star filled" : "star"}>
            ★
          </i>
        ))}
      </div>
    );
  };

  if (testimonials.length === 0) {
    return (
      <div className="testimonial-container">No testimonials available.</div>
    );
  }

  return (
    <div className="testimonial-container">
      <div className="testimonial-title">Client Testimonials</div>
      <div
        className={`testimonial-content ${animationDirection} ${
          lineAnimating ? "animate-line" : ""
        }`}
      >
        <div className="testimonial-text">
          {testimonials[currentIndex].text}
        </div>
        <StarRating rating={testimonials[currentIndex].rating} />
        <div className="testimonial-company">
          {testimonials[currentIndex].company}
        </div>
        <div className="testimonial-position">
          {testimonials[currentIndex].position}
        </div>
      </div>
      <div className="dot-indicators">
        {testimonials.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialSlider;

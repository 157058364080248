import React from "react";
import "./Blog.css";
import BlogIMG1 from "../../Asset/Image/BlogIMG1.png";
import DeveloperIMG1 from "../../Asset/Image/DeveloperIMG1.png";
import DeveloperIMG2 from "../../Asset/Image/DeveloperIMG2.png";
import DeveloperIMG3 from "../../Asset/Image/DeveloperIMG3.png";

const blogPosts = [
  {
    date: "August 7, 2024",
    title:
      "Revolutionizing Thrills: The Essential VR Experiences Reshaping Amusement Parks in 2024",
    knowMoreLink:
      "https://www.tiltlabs.io/essential-vr-experiences-reshaping-amusement-parks-in-2024/",
  },
  {
    date: "August 5, 2024",
    title: "Apple Vision Pro Blazes a Trail for the Future of Business",
    knowMoreLink:
      "https://www.tiltlabs.io/apple-vision-pro-the-future-of-business/",
  },
  {
    date: "August 3, 2024",
    title:
      "Unlocking the Future of Retail: How AR and VR are Transforming Shopping Experiences.",
    knowMoreLink:
      "https://www.tiltlabs.io/how-ar-and-vr-are-transforming-retail-shopping-experiences/",
  },
  {
    date: "August 1, 2024",
    title: "The Metaverse: Far More Than a Passing Trend.",
    knowMoreLink:
      "https://www.tiltlabs.io/metaverse-far-more-than-passing-trend/",
  },
];

const blogs = [
  {
    title:
      "Revolutionizing Thrills: The Essential VR Experiences Reshaping Amusement Parks in 2024",
    description:
      "The world of amusement parks and experience centers is on the brink of a technological revolution. As we venture deeper into 2024, virtual reality (VR)....",
    image: DeveloperIMG1,
    knowMoreLink:
      "https://www.tiltlabs.io/essential-vr-experiences-reshaping-amusement-parks-in-2024/",
  },
  {
    title: "Apple Vision Pro Blazes a Trail for the Future of Business.",
    description:
      "In a world driven by innovation, Apple has raised the bar with its groundbreaking Vision Pro headset. This revolutionary device transcends technology, propelling businesses into an era of boundless possibilities...",
    image: DeveloperIMG2,
    knowMoreLink:
      "https://www.tiltlabs.io/apple-vision-pro-the-future-of-business/",
  },
  {
    title:
      "Unlocking the Future of Retail: How AR and VR are Transforming Shopping Experiences.",
    description:
      "In the rapidly evolving world of retail, businesses are constantly seeking innovative ways to captivate customers and provide truly immersive shopping journeys. Two groundbreaking technologies – augmented reality (AR) and virtual reality (VR) are at the forefront of this revolution",
    image: DeveloperIMG3,
    knowMoreLink:
      "https://www.tiltlabs.io/how-ar-and-vr-are-transforming-retail-shopping-experiences/",
  },
  {
    title: "The Metaverse: Far More Than a Passing Trend.",
    description:
      "The metaverse has dominated tech discourse as the next evolution of the internet. While still in its nascent stages, early adoption has shown that the metaverse is far more than a passing fad. It presents transformational opportunities for businesses to reimagine engagement, c...",
    image: DeveloperIMG3,
    knowMoreLink:
      "https://www.tiltlabs.io/metaverse-far-more-than-passing-trend/",
  },
  {
    title: "The Future of 3D Visualization: Trends and Innovations to Watch.",
    description:
      "The world of 3D visualization is experiencing a dynamic evolution driven by exciting trends and innovative technologies. From virtual reality (VR) and augmented reality (AR) integration..",
    image: DeveloperIMG1,
    knowMoreLink: "https://www.tiltlabs.io/the-future-of-3d-visualization/",
  },
  {
    title: "How 5G Enhances VR Experience?.",
    description:
      "5G has been associated with the possibility of new digital applications and services that accompany a hyper-connected life . We’ve seen how this new technology, which permits  “perfect” connectivity, adds additional value to both consumers and enterprises.",
    image: DeveloperIMG2,
    knowMoreLink: "https://www.tiltlabs.io/5g-enhances-vr-experience/",
  },
];

const developerBlogs = [
  {
    title:
      "Unreal Engine 5.3 Revolutionizes XR Development with Groundbreaking Features",
    description:
      "The latest version of Unreal Engine introduces a suite of cutting-edge tools and APIs that empower developers to create truly immersive extended reality experiences.",
    image: DeveloperIMG1,
    knowMoreLink: "https://www.example.com/dev-blog-1-details",
  },
  {
    title:
      "Meta's Project Aria: Unlocking the Potential of Everyday Augmented Reality",
    description:
      "Meta s groundbreaking Project Aria is poised to revolutionize how we perceive and interact with the world around us, blending digital information seamlessly into our physical environments.",
    image: DeveloperIMG2,
    knowMoreLink: "https://www.example.com/dev-blog-2-details",
  },
  {
    title: "Apple Vision Pro: Redefining the Future of Spatial Computing",
    description:
      "The upcoming Apple Vision Pro headset promises to revolutionize the way we interact with and experience the digital world, blurring the lines between reality and imagination.",
    image: DeveloperIMG3,
    knowMoreLink: "https://www.example.com/dev-blog-3-details",
  },
  {
    title: "Exploring the Untapped Potential of Location-Based AR Experiences",
    description:
      "As AR technology matures, developers are uncovering new ways to leverage location data and context-aware features to create captivating and immersive experiences.",
    image: DeveloperIMG3,
    knowMoreLink: "https://aircada.com/navigation-geolocation-with-web-ar/",
  },
];

const Blog = () => {
  const handleKnowMore = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="blog-container">
      <div className="Blog_header">
        <h1 className="blog-title">Blogs</h1>
        <p className="blog-subtitle">
          Read our latest blogs on Extended Reality
        </p>
      </div>
      <div className="Blog_content">
        <div className="latest-blog">
          <img src={BlogIMG1} alt="Latest Blog" className="latest-image" />
          <span className="latest-label">Latest</span>
          <h2 className="latest-title">
            Revolutionizing Thrills: The Essential VR Experiences Reshaping
            Amusement Parks in 2024
          </h2>
        </div>
        <div className="other-blogs">
          {blogPosts.map((blog, index) => (
            <div key={index} className="blog-entry">
              {/* <p className="blog-date">{blog.date}</p> */}
              <h3 className="blog-titl">{blog.title}</h3>
              <button
                className="know-more-btn"
                onClick={() => handleKnowMore(blog.knowMoreLink)}
              >
                Know More
              </button>
            </div>
          ))}
        </div>
      </div>
      <h2 className="blog-title">Latest</h2>
      <div className="blogs-grid">
        {blogs.map((blog, index) => (
          <div key={index} className="blog-entry">
            <img src={blog.image} alt={blog.title} className="blog-image" />
            <div className="blog-content">
              <h3 className="blog-entry-title">{blog.title}</h3>
              <p className="blog-description">{blog.description}</p>
              <button
                className="know-more-btn"
                onClick={() => handleKnowMore(blog.knowMoreLink)}
              >
                Know More
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* <h2 className="blog-title">From our developers</h2>
      <div className="developers-grid">
        {developerBlogs.map((blog, index) => (
          <div key={index} className="developer-entry">
            <img
              src={blog.image}
              alt={blog.title}
              className="developer-image"
            />
            <div className="developer-content">
              <h3 className="developer-title">{blog.title}</h3>
              <p className="developer-description">{blog.description}</p>
              <button
                className="know-more-btn"
                onClick={() => handleKnowMore(blog.knowMoreLink)}
              >
                Know More
              </button>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Blog;
